import React from "react"
import SiteSocialMediaLinks from "@components/SiteSocialMediaLinks"
import { Link } from "gatsby"

const Footer: React.FC = () => (
  <footer className="py-12 mt-14 ">
    <div className="container--boxed flex items-center justify-between">
      <div className="text-sm text-slate-500 dark:text-slate-500">
        &copy; {new Date().getFullYear()} Andrew Ng
      </div>
      <nav className="items-center justify-center hidden md:flex">
        <Link to="/about/" className="text-sm text-slate-500">
          About
        </Link>
        <div className="px-3 text-slate-300 text-base">&middot;</div>
        <Link to="/contact/" className="text-sm text-slate-500">
          Contact
        </Link>
        <div className="px-3 text-slate-300 text-base">&middot;</div>
        <Link to="/faq/" className="text-sm text-slate-500">
          FAQs
        </Link>
      </nav>
      <div>
        <SiteSocialMediaLinks />
      </div>
    </div>
  </footer>
)

export default Footer
