import React from "react"
import Header from "@components/Header"
import Footer from "@components/Footer"

const Layout: React.FC = ({ children }) => {
  return (
    <div className="bg-white dark:bg-slate-900">
      {/* <div className="absolute top-0 w-full h-80 bg-gradient-to-b from-slate-100 to-transparent dark:bg-none"></div> */}
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
