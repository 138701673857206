import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Nav from "./Nav"
import SocialMediaIcons from "./SocialMediaIcons"
import MobileMenu from "./MobileMenu"
import DarkModeToggle from "./DarkModeToggle"

const Header = () => {
  const [isSticky, setSticky] = useState(false)

  useEffect(() => {
    let makeHeaderSticky = function () {
      let breakpoint = 0
      if (
        document.body.scrollTop > breakpoint ||
        document.documentElement.scrollTop > breakpoint
      ) {
        setSticky(true)
      } else {
        setSticky(false)
      }
    }
    window.addEventListener("scroll", makeHeaderSticky)
    return () => {
      window.removeEventListener("scroll", makeHeaderSticky)
    }
  }, [])

  return (
    <header
      className={`flex items-center w-full sticky top-0 z-40 transition-all bg-none dark:bg-slate-900 ${
        isSticky ? "h-14 md:h-14 bg-slate-50 dark:bg-slate-800" : "h-20 md:h-32"
      }`}
    >
      <nav className="container--boxed w-full flex items-center justify-between">
        <div>
          <Link
            to="/"
            className={`text-xl font-semibold text-neutral-900 dark:text-slate-200 transition-all ${
              isSticky ? "md:text-xl" : "md:text-2xl"
            }`}
          >
            Andrew Ng
          </Link>
        </div>
        <div className="flex items-center">
          <div className="items-center hidden lg:flex">
            <Nav />
            <div className="ml-9">
              <SocialMediaIcons />
            </div>
            <div className="ml-4">
              <DarkModeToggle />
            </div>
          </div>
          <div className="lg:hidden flex items-center">
            <div className="mr-3">
              <DarkModeToggle />
            </div>
            <MobileMenu />
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
