import React from "react"
import { Link } from "gatsby"
import { Menu, Transition } from "@headlessui/react"
import { FiMenu } from "react-icons/fi"
import SocialMediaIcons from "./SocialMediaIcons"

const MobileMenu = () => {
  return (
    <Menu>
      <Menu.Button>
        <FiMenu className="text-2xl text-slate-800 dark:text-slate-300" />
      </Menu.Button>

      <Transition
        enter="transform transition ease-in-out duration-250 sm:duration-250"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transform transition ease-in-out duration-300 sm:duration-300"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        className="fixed inset-0 z-50 bg-white dark:bg-slate-800 h-full w-3/4 shadow-xl overflow-y-scroll"
      >
        <Menu.Items className="flex flex-col justify-between h-full">
          <div className="py-3">
            <Menu.Item>
              {({ active }) => (
                <MenuItemLink href="/about/" {...{ active }}>
                  About
                </MenuItemLink>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <MenuItemLink href="/publications/" {...{ active }}>
                  Publications
                </MenuItemLink>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <MenuItemLink href="/projects/" {...{ active }}>
                  Projects
                </MenuItemLink>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <MenuItemLink href="/courses/" {...{ active }}>
                  Courses
                </MenuItemLink>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <MenuItemLink href="https://datacentricai.org/" {...{ active }}>
                  Data-centric AI
                </MenuItemLink>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <MenuItemLink href="/contact/" {...{ active }}>
                  Contact
                </MenuItemLink>
              )}
            </Menu.Item>
          </div>
          <div className="p-5 mb-2">
            <SocialMediaIcons />
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

const MenuItemLink: React.FC<{
  href: string
}> = ({ href, children, ...rest }) => {
  return (
    <Link
      to={href}
      activeClassName="bg-slate-100 border-solid border-l-4 border-l-gray-900"
      className={`w-full min-h-[44px] text-base flex items-center px-5 text-slate-700 dark:text-slate-300 font-[450]`}
      {...rest}
    >
      {children}
    </Link>
  )
}

export default MobileMenu
