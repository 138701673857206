import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"

type Props = {
  title?: string
  description?: string
  yoastSeo?: {
    title?: string
    metaKeywords?: string
    metaDesc?: string
    opengraphDescription?: string
    opengraphModifiedTime?: string
    opengraphPublishedTime?: string
    opengraphSiteName?: string
    opengraphTitle?: string
    opengraphType?: string
    readingTime?: string
    twitterDescription?: string
    twitterTitle?: string
  }
  image?: string
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    wp {
      generalSettings {
        title
        description
      }
    }
  }
`

const Seo: React.FC<Props> = ({ title, description, yoastSeo, image }) => {
  const { pathname } = useLocation()
  const data = useStaticQuery(query)

  const meta = {
    title:
      title ||
      yoastSeo?.title ||
      data?.wp?.generalSettings?.title ||
      data?.site?.siteMetadata?.title,
    description:
      description ||
      yoastSeo?.metaDesc ||
      yoastSeo?.opengraphDescription ||
      data?.wp?.generalSettings?.description ||
      "",
    canonical: `${data?.site?.siteMetadata?.siteUrl}${pathname}`,
    image: image
      ? new URL(image, data?.site?.siteMetadata?.siteUrl).href
      : null,
  }

  const og = {
    title: yoastSeo?.opengraphTitle || meta.title,
    description: yoastSeo?.opengraphDescription || meta.description,
    type: yoastSeo?.opengraphType || "website",
    url: meta.canonical,
    siteName: yoastSeo?.opengraphSiteName || data?.site?.siteMetadata?.title,
    image: meta.image,
  }

  const twitter = {
    title: yoastSeo?.twitterTitle || meta.title,
    description: yoastSeo?.twitterDescription || meta.description,
    url: meta.canonical,
    siteName: og.siteName,
    image: meta.image,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <title>{meta.title}</title>
      {meta.description && (
        <meta name="description" content={meta.description} />
      )}
      <link rel="canonical" href={meta.canonical} />

      <meta property="og:site_name" content={og.siteName} />
      <meta property="og:type" content={og.type} />
      <meta property="og:title" content={og.title} />
      {og.description && (
        <meta property="og:description" content={og.description} />
      )}
      <meta property="og:url" content={og.url} />
      {og.image && <meta property="og:image" content={og.image} />}

      <meta name="twitter:title" content={twitter.title} />
      {twitter.description && (
        <meta name="twitter:description" content={twitter.description} />
      )}
      <meta name="twitter:url" content={twitter.url} />
      {twitter.image && <meta name="twitter:image" content={twitter.image} />}
      {twitter.image ? (
        <meta name="twitter:card" content="summary_large_image" />
      ) : (
        <meta name="twitter:card" content="summary" />
      )}
    </Helmet>
  )
}

export default Seo
