import React from "react"
import { BsTwitter, BsLinkedin } from "react-icons/bs"

const SocialMediaIcons = () => {
  return (
    <ul className="list-none flex items-center gap-4">
      <Icon title="Twitter" url="https://twitter.com/AndrewYNg">
        <BsTwitter />
      </Icon>
      <Icon title="Linkedin" url="https://www.linkedin.com/in/andrewyng/">
        <BsLinkedin />
      </Icon>
    </ul>
  )
}

const Icon = ({ title, url, children }) => (
  <li>
    <a
      href={url}
      rel="noopener noreferrer"
      aria-label={title}
      className="text-xl text-neutral-900 dark:text-slate-200 hover:text-neutral-700 dark:hover:text-slate-100"
      target="_blank"
    >
      {children}
    </a>
  </li>
)

export default SocialMediaIcons
