import React, { useEffect } from "react"
import { CgDarkMode } from "react-icons/cg"

const DarkModeToggle = () => {
  const toggleTheme = () => {
    const theme = localStorage.getItem("theme")

    if (theme === "dark") {
      localStorage.setItem("theme", "light")
      document.documentElement.classList.remove("dark")
    } else {
      localStorage.setItem("theme", "dark")
      document.documentElement.classList.add("dark")
    }
  }

  useEffect(() => {
    if (localStorage.theme === "dark") {
      document.documentElement.classList.add("dark")
    }
  }, [])

  return (
    <button
      className="text-2xl flex items-center justify-center p-0 m-0"
      onClick={() => toggleTheme()}
    >
      <CgDarkMode />
    </button>
  )
}

export default DarkModeToggle
