import React from "react"
import { Link } from "gatsby"
import { FiArrowUpRight } from "react-icons/fi"

type Props = {
  title: string
  link: {
    type: "internal" | "external"
    url: string
  }
}

const NavItem: React.FC<Props> = ({ title, link }) => {
  return (
    <li className="ml-9 first:ml-0">
      {link.type === "internal" ? (
        <Link
          className="text-base text-neutral-900 dark:text-slate-300 font-[450] relative after:content-[''] after:absolute after:bottom-[-4px] after:left-0 after:w-0 after:h-[2px] after:bg-gray-900 dark:after:bg-slate-300 after:transition-all after:duration-200 hover:after:w-full"
          to={link.url}
          activeClassName="after:w-full"
          partiallyActive={true}
        >
          {title}
        </Link>
      ) : (
        <a
          className="text-base text-neutral-900 dark:text-slate-300 font-[450] relative after:content-[''] after:absolute after:bottom-[-4px] after:left-0 after:w-0 after:h-[2px] after:bg-gray-900 dark:after:bg-slate-300 after:transition-all after:duration-200 hover:after:w-full group"
          href={link.url}
          target="_blank"
        >
          {title}
          <span className="hidden group-hover:flex absolute -top-3 -right-4 text-base text-slate-300 dark:text-slate-500">
            <FiArrowUpRight />
          </span>
        </a>
      )}
    </li>
  )
}

export default NavItem
