import React from "react"
import { BsTwitter, BsLinkedin, BsFacebook } from "react-icons/bs"

const SiteSocialMediaLinks = () => {
  return (
    <ul className="list-none flex items-center gap-3">
      <Icon title="Twitter" url="https://twitter.com/AndrewYNg">
        <BsTwitter />
      </Icon>
      <Icon title="Linkedin" url="https://www.linkedin.com/in/andrewyng/">
        <BsLinkedin />
      </Icon>
      <Icon title="Facebook" url="https://www.facebook.com/andrew.ng.96">
        <BsFacebook />
      </Icon>
    </ul>
  )
}

const Icon = ({ title, url, children }) => (
  <li>
    <a
      href={url}
      rel="noopener noreferrer"
      aria-label={title}
      className="text-xl text-slate-500 dark:text-slate-300 transition-colors hover:text-slate-600 dark:hover:text-slate-100"
      target="_blank"
    >
      {children}
    </a>
  </li>
)

export default SiteSocialMediaLinks
