import React from "react"
import NavItem from "./NavItem"

const Nav: React.FC = () => {
  return (
    <ul className="list-none flex items-center ">
      <NavItem title="About" link={{ type: "internal", url: "/about/" }} />
      <NavItem
        title="Publications"
        link={{ type: "internal", url: "/publications/" }}
      />
      <NavItem
        title="Projects"
        link={{ type: "internal", url: "/projects/" }}
      />
      <NavItem title="Courses" link={{ type: "internal", url: "/courses/" }} />
      <NavItem
        title="Data-centric AI"
        link={{ type: "external", url: "https://datacentricai.org/" }}
      />
      <NavItem title="Contact" link={{ type: "internal", url: "/contact/" }} />
    </ul>
  )
}

export default Nav
